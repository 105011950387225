@font-face {
  font-family: "DMSanslight";
  font-weight: 300;
  src: url("../src/assets/fonts/DMSans_18pt-Light.ttf");
}
@font-face {
  font-family: "DMSansRegular";
  font-weight: 400;
  src: url("../src/assets/fonts/DMSans_18pt-Regular.ttf");
}
@font-face {
  font-family: "DMSansMedium";
  font-weight: 500;
  src: url("../src/assets/fonts/DMSans_18pt-Medium.ttf");
}
@font-face {
  font-family: "DMSansSemiBold";
  font-weight: 600;
  src: url("../src/assets/fonts/DMSans_18pt-SemiBold.ttf");
}
@font-face {
  font-family: "DMSansBold";
  font-weight: 700;
  src: url("../src/assets/fonts/DMSans_18pt-Bold.ttf");
}
:root {
  --main-color-dark: #303030;
  --main-color-light: #ffffff;
  --main-navlink-color: #0692fc;
  --main-btn-color: #221f48;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DMSansMedium";
}
p {
  font-family: "DMSansRegular";
}
.nav-link {
  cursor: pointer;
}
/* ___Only Buttons_______________________________ */
.main_Btn {
  color: var(--main-btn-color);
  font-family: "DMSansSemiBold" !important;
  font-weight: 600;
  padding: 12px 35px 12px 35px;
  box-shadow: 1px 2px 8px 0px #00000040;
  font-size: 18px;
  border: 1px solid transparent;
  background-color: white;
}
.main_Btn:hover {
  color: white;
  background-color: var(--main-btn-color);
}
@media screen and (max-width: 768px) {
  .main_Btn {
    padding: 8px 25px 8px 25px;
    font-size: 15px;
  }
}
/* ___NAVBAR______________________________________ */
.navbar_home .nav-link {
  color: var(--main-color-dark) !important;
  font-family: "DMSansSemiBold" !important;
  font-weight: 600;
}
.navbar_home .nav-link:hover {
  color: var(--main-btn-color) !important;
}
.navbar-toggler {
  box-shadow: none !important;
}
.loginSearchBtn {
  cursor: pointer;
}
/* ___HomePage (Hero Section 1)________________________ */
.heroSectionh3 {
  color: var(--main-color-light) !important;
  font-family: "DMSansBold" !important;
  font-weight: 700;
}
.heroSectionh1 {
  color: var(--main-color-light) !important;
  font-family: "DMSansBold" !important;
  font-weight: 700;
}
.heroSectionParagraph {
  color: var(--main-color-light) !important;
  font-family: "DMSansRegular" !important;
  font-weight: 400;
  font-size: 14px;
}
.video_div {
  height: 480px;
  background-color: #000;
}
/* ___HomePage (Experience Section 2)____________ */
.experienceBox {
  box-shadow: 1px 2px 8px 0px #00000040;
  background-color: #f4f4f4 !important;
}
.experienceBox1 {
  padding: 70px 100px 70px 100px;
}
.experienceBox2 {
  box-shadow: 1px 2px 8px 0px #00000040;
  background-color: white;
}
.experienceBox2:hover {
  background-color: #f4f4f4;
}
.experienceh4 {
  font-family: "DMSansBold";
  font-weight: 700;
}
.experiencepara {
  font-family: "DMSansRegular" !important;
  font-weight: 400;
  font-size: 14px;
}
.experienceh5 {
  font-family: "DMSansBold";
  font-weight: 700;
}
.experiencepara1 {
  font-family: "DMSansLight";
  font-weight: 300;
  font-size: 11px;
  height: 100px;
}
/* ___HomePage (Empower Section 3)____________ */
.empower_back {
  background-image: url("./assets/images/HomePage/Empower_back.jpg");
  background-position: center;
  background-size: contain;
  background-attachment: fixed;
}
.empower_img {
  margin-left: -20%;
}
/* ___HomePage (Features Section 4)____________ */
.featureBox {
  box-shadow: 1px 2px 8px 0px #00000040;
  background-color: #f4f4f4 !important;
}
.featureh2 {
  font-family: "DMSansSemiBold";
  font-weight: 600;
  font-size: 37px;
}
.featurepara {
  font-family: "DMSansRegular";
  font-weight: 400;
  word-spacing: 5px;
  font-size: 14px;
}
.featureBox2 {
  background: linear-gradient(180deg, #2e2a61 -2.13%, #0b0a17 102.3%);
}
#Feature_video1 {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  border-bottom: 2px solid green;
  border-left: 2px solid green;
}
.featureh3_1 {
  font-family: "DMSansMedium";
  font-weight: 500;
}
.featurepara_1 {
  font-family: "DMSanslight";
  font-weight: 300;
  word-spacing: 7px;
  font-size: 14.5px;
  border-top: 2px dashed white;
  border-bottom: 2px dashed white;
}
/* ___Media Query For Responsivness_________________ */
@media screen and (max-width: 1024px) {
  .experienceh5 {
    font-size: 15px;
  }
  .empower_img {
    margin-left: 0% !important;
  }
}
@media screen and (max-width: 768px) {
  .heroSectionh3 {
    font-size: 16px;
  }
  .heroSectionh1 {
    font-size: 18px;
  }
  .video_div {
    height: 380px;
  }
}
@media screen and (max-width: 576px) {
  .video_div {
    height: 180px;
  }
  .experienceBox1 {
    padding: 20px 20px 20px 20px;
  }
  .experienceh4 {
    font-size: 22px;
  }
  .experiencepara {
    font-size: 10.5px;
  }
  .empower_back {
    background-size: cover;
  }
  .featureh2 {
    font-size: 23px;
  }
  .featurepara {
    font-size: 9.5px;
  }
  .featurepara_1 {
    word-spacing: 2px !important;
    font-size: 11px;
  }
  .featureh3_1{
font-size: 19px;
  }
}
/* ___Media Query For Responsivness_________________ */
